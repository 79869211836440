@use 'src/assets/scss/mixins/breakpoints';

.visible-mobile {
  @include breakpoints.breakpoint(min-tablet) {
    display: none !important;
  }
}

.visible-tablet {
  @include breakpoints.breakpoint(min-desktop) {
    display: none !important;
  }
}

.hidden-mobile {
  @include breakpoints.breakpoint(only-mobile) {
    display: none !important;
  }
}

.hidden-tablet {
  @include breakpoints.breakpoint(max-tablet) {
    display: none !important;
  }
}
