@use "../../../assets/scss/vars/colors";
@use "../../../assets/scss/vars/sizes";
@use "../../../assets/scss/vars/borders";


.switch[type="checkbox"] {
  display: none;
  &:checked + label {
    background-color: colors.$color-blue;
  }
  &:checked + label:after{
    left: sizes.$size-large;
  }
}

.switch + label {
  transition: all 200ms ease-in-out;
  display: inline-block;
  position: relative;
  height: sizes.$size-large;
  width: sizes.$size-xxxlarge;
  border-radius: borders.$border-radius-rounded;
  cursor: pointer;
  background-color: colors.$color-gray;
  color: transparent;
  vertical-align: middle;

  &:after {
    transition: all 200ms ease-in-out;
    content: ' ';
    position: absolute;
    height: sizes.$size-large;
    width: sizes.$size-large;
    border-radius: borders.$border-radius-circle;
    background-color: colors.$color-white;
    top: 0;
    left: 0;
    right: auto;
    box-shadow: 1px 1px 1px colors.$color-gray;
    transform: scale(0.9);
  }
}
