$size-base: 1rem; // 16px

$size-mini: $size-base * 0.25; //  4px
$size-micro: $size-base * .375; // 6px
$size-tiny: $size-base * 0.5; //  8px
$size-small: $size-base * 0.75; // 12px
$size-medium: $size-base * 1.5; // 24px
$size-large: $size-base * 2; // 32px
$size-xlarge: $size-base * 2.5; // 40px
$size-xxlarge: $size-base * 3; // 48px
$size-xxxlarge: $size-base * 4; // 64px
