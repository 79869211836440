@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';

@include breakpoints.breakpoint(max-tablet) {
  .bottomsheet {
    opacity: 0;
    position: fixed;
    left: sizes.$size-tiny;
    right: sizes.$size-tiny;
    bottom: -100%;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transitions.$transition-all-fast;
    transition-property: bottom, background-color, opacity;
    max-width: 500px;
    margin: 0 auto;
    z-index: variables.$z-index-root + 1;

    &::after {
      background: transparent;
    }

    .card {
      background: colors.$color-white;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--open {
      opacity: 1;
      bottom: 5.3rem;

      &::after {
        content: "";
        display: block;
        position: fixed;
        z-index: -(variables.$z-index-root);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: colors.$translucent-black;
      }
    }
  }
}
