@use "../../../assets/scss/vars/colors";
@use "../../../assets/scss/vars/sizes";
@use "../../../assets/scss/vars/borders";
@use "../../../assets/scss/vars/typography";

$form-label-left-padding: sizes.$size-base;

input[type="text"],
input[type="number"],
input[type="search"],
input[type="checkbox"],
input[type="radio"],
input[type="email"],
input[type="password"],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="text"],
input[type="number"],
input[type="search"],
input[type="email"],
input[type="password"],
select, .form-select {
  display: block;
  width: 100%;
  background-color: var(--form-input-bg);
  border: 1px solid var(--form-input-border);
  border-radius: sizes.$size-medium;
  padding: 0 sizes.$size-base;
  color: var(--item-color-text);
  font-size: sizes.$size-base;
  height: sizes.$size-large;

  &:focus {
    box-shadow: borders.$shadow-small;
    border-color: colors.$color-blue;
    background-color: var(--form-input-bg);
    outline: none;
  }
}

input[type="radio"] {
  &::after {
    border-radius: borders.$border-radius-circle;
  }
}

input[type="checkbox"] {
  &::after {
    border-radius: 2px;
  }
}

input[type="checkbox"],
input[type="radio"] {
  margin-left: 0;
  margin-right: 0;

  &::after {
    content: "";
    display: block;
    width: 1em;
    height: 1em;
    background: colors.$color-white;
    border: 1px solid colors.$color-gray-dark;
  }

  &:checked::after {
    background: colors.$color-blue;
    border: 2px solid colors.$color-white;
    outline: 1px solid colors.$color-gray-dark;

    // Adjust the size to make it the same as non-checked radios
    width: calc(1em - 2px);
    height: calc(1em - 2px);
  }
}


form {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;

    legend {
      padding: 0;
    }

    label,
    .label {
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  legend,
  label,
  .label {
    display: block;
    margin-bottom: sizes.$size-tiny;
    font-weight: bold;
  }

  .form-item {
    .label,
    label {
      font-size: typography.$font-size-small!important;
      padding-left: $form-label-left-padding;
      font-weight: bold!important;
    }
  }

  fieldset,
  .form-item {
    &:not(:last-child) {
      margin-bottom: sizes.$size-base;
    }

    .form-item-check {
      display: flex;
      flex-wrap: wrap;
      column-gap: sizes.$size-tiny;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: sizes.$size-tiny;
      }

      &.no-wrap {
        flex-wrap: nowrap;
      }
    }
  }

  .form-item--inline {
    display: flex;
    column-gap: sizes.$size-base;

    .form-item-check {
      padding-bottom: sizes.$size-tiny;
    }
  }
}


// Class to make the label indented to right and bold 12px.
// Use in case the structure of the elements does not follow standard forms convention - for example legend used as label.
// TODO: Refactor this approach.
.form-item-label {
  padding-left: $form-label-left-padding;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}

.ng-invalid {
  form.ng-touched & {
    &input[type="text"],
    &input[type="search"] {
      border: 1px solid colors.$color-red;
    }

    ~ .invalid-feedback {
      display: block;
    }
  }
}

.form-text,
.invalid-feedback {
  padding-left: $form-label-left-padding;
  font-size: small;
}

.form-text {
  color: colors.$color-gray-medium;
}

.invalid-feedback {
  display: none;
  margin-top: sizes.$size-mini;
  color: colors.$color-red;

  .form-item-check & {
    flex-basis: 100%;
    margin-bottom: sizes.$size-tiny;
  }
}

.select-arrow--wrapper {
  position: relative;
  max-width: 210px;
  width: 100%;

  &::after {
    content: "";
    border: solid colors.$color-text;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    position: absolute;
    rotate: 45deg;
    right: 10px;
    top: 15px;
  }
}

.prefix-phone--container {
  display: flex;

  .phone-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .prefix-dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
