@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'src/assets/scss/mixins/breakpoints';

.broadcast-item {
  position: relative;

  &:hover,
  &:focus-within {
    background-color: var(--card-hover);
  }

  &__body,
  &__info {
    min-width: 0;
    overflow: hidden;
  }

  &__body {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
  }

  &__station-logo {
    padding: sizes.$size-base;
  }

  &__info {
    flex: 1;
    display: flex;
    row-gap: sizes.$size-mini;
    align-items: center;
    padding: sizes.$size-base 0;
    color: var(--item-color-text) !important;

    @include breakpoints.breakpoint(max-tablet) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__title,
  &__begin {
    flex: 1;
    min-width: 0;
    padding-right: sizes.$size-base;
    font-size: calc(sizes.$size-base * 0.9);
  }

  &__title {
    h3 {
      display: flex;
      gap: sizes.$size-mini;
    }
  }

  &__actions {
    display: flex;
    column-gap: 5px;
    flex-direction: row-reverse;
    padding: sizes.$size-base;
  }
}
