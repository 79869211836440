@use './sizes';
@use './colors';
@import url('https://fonts.googleapis.com/css?family=Raleway:200,200i,400,400i,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:200,200i,400,400i,600&display=swap');

$font-family-body: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-header: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size: 16px;
$font-size-mobile: 14px;
$font-size-small: 12px;
$font-size-desktop-large: 2vh;
$line-height: 1.4;

@mixin lineLimit($lines) {
  max-height: calc(#{$lines}em * #{$line-height});
  overflow: hidden;
}

// Headings, Texts, Links
h1, .h1,
h2, .h2,
h3, .h3 {
  margin: 0 0 .5em;
}

h1, .h1,
h2, .h2 {
  font-family: $font-family-header;
}

h2, .h2,
h3, .h3 {
  font-weight: 600;
}

h1, .h1 {
  font-size: sizes.$size-large;
  font-weight: 300;
}

h2, .h2 {
  font-size: sizes.$size-medium;
}

h3, .h3 {
  font-size: sizes.$size-base;
  margin-bottom: sizes.$size-base;
  font-family: $font-family-body;
}

p {
  margin: 0 0 .5em;
}

h1, h2, h3, p, .h1, .h2, .h3 {
  &.collapse {
    margin-bottom: 0;
  }
}

small,
.small {
  font-size: sizes.$size-small;
  font-family: $font-family-body;
}

.small-bold {
  font-size: sizes.$size-small;
  margin: 0;
  font-weight: bold;
  font-family: $font-family-body;
}

.tc-info {
  color: colors.$color-gray-medium;
}

a,
a:link,
a:visited {
  text-decoration: none;
  color: colors.$color-blue;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }

  h3 &,
  .h3 &,
  &.h3 {
    color: inherit;
  }

  &.text-link {
    color: inherit;
  }

  &.no-underline {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
