@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/typography';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/helpers';
@use 'src/assets/scss/layout/variables';

.tabbar {
  transition: transitions.$transition-all-fast;
  display: flex;
  align-items: center;
  align-content: stretch;
  padding: 0 sizes.$size-large 0 6rem;
  position: fixed;
  height: 6rem;
  top: 0;
  right: 0;
  left: 0;
  z-index: variables.$z-index-root + 11;

  &::before {
    content: '';
    transition: transitions.$transition-all-fast;
    background-color: transparent;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 6rem;
    z-index: variables.$z-index-root + 12;
  }

  &__right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: sizes.$size-base;
    z-index: variables.$z-index-root + 13;
    padding-left: sizes.$size-base;
    max-width: 100%;

    .no-shrink {
      flex-shrink: 0;
    }
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: variables.$z-index-root + 13;
    min-width: 0;
  }

  > .btn {
    transition: transitions.$transition-all-fast;
    margin-right: 0.6rem;
  }

  @include breakpoints.breakpoint(max-tablet) {
    left: 0;
    height: 4rem;
    padding-right: sizes.$size-base;

    &:before {
      height: 4rem;
    }

    &__title {
      display: none;
    }
  }
}

.layout-wrapper--scrolled {
  .tabbar {

    &:before {
      transition: transitions.$transition-all-fast;
      box-shadow: borders.$shadow-small;
      background-color: var(--tabbar-scrolled-bg);
    }

    &__title {
      transition: transitions.$transition-all-fast;
      font-size: typography.$font-size;
    }

    &__right .search__btn .btn,
    &__right .btn__select,
    &__right > .btn:not(.btn__primary) {
      transition: transitions.$transition-all-fast;
      background-color: var(--tabbar-scrolled-button-bg) !important;
    }

    .btn--xl {
      transition: transitions.$transition-all-fast;
      padding: sizes.$size-tiny;
      font-size: sizes.$size-medium;
    }
  }
}
