@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/layout/helpers';

// DO NOT SHOW UI OF PLAYER ON INITIAL LOADING
body:not(.player-large, .player-mini) {
  .bmpui-ui-controlbar,
  .jumpseek-container,
  .player-header,
  .btn__next-episode {
    display: none;
  }
}

// STYLING ON PLAYER VISIBLE OUTSIDE OF PLAYER.COMPONENTE
.player-large {
  background-color: colors.$color-black;

  .layout-wrapper__sidenav {
    max-height: calc(100vh - #{sizes.$size-base * 6});
  }

  @include breakpoints.breakpoint(max-tablet-landscape) {
    .layout-wrapper__sidenav {
      display: none;
    }
  }

  @include breakpoints.breakpoint(min-desktop) {
    .player[data-fullscreen="true"] {
      video {
        height: auto !important;
        width: 100% !important;
      }
    }
  }
}

.player-mini {
  .player:not([data-fullscreen="true"]) {
    .bmpui-ui-hugeplaybacktogglebutton::before {
      max-height: sizes.$size-base * 5;
      max-width: sizes.$size-base * 5;
    }

    .bmpui-ui-controlbar {
      display: none;
    }

    // Fix for anonymous user mini player not scaling the video properly.
    @include breakpoints.breakpoint(min-desktop) {
      video {
        height: auto!important;
        width: 100%!important;
      }
    }

  }

  @include breakpoints.breakpoint(max-tablet) {
    .recording-batch__background {
      bottom: sizes.$size-base * 13 !important;
    }

    .player:not([data-fullscreen="true"]) {
      .bitmovinplayer-container video,
      .bitmovinplayer-ad-container video {
        z-index: variables.$z-index-player + 1;
      }
    }
  }

  @include breakpoints.breakpoint(only-mobile) {
    .recording-batch__background {
      bottom: sizes.$size-base * 10 !important;
    }
  }
}

.player-large.player-listing-open {
  .player-wrapper {
    .broadcast-item__info {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @include breakpoints.breakpoint(min-desktop) {
      .bmpui-ui-controlbar {
        display: none;
      }
    }
  }
}

.player-large:not(.player-listing-open) {
  @include breakpoints.breakpoint(max-tablet-portrait) {
    .player-wrapper .player:not([data-fullscreen="true"]) {
      height: 100%;

      .jumpseek-button {
        top: auto;
        bottom: 18%;
        transform: translate(-50%, 50%);

        .btn {
          background-color: transparent;
        }
      }

      .player-listing__btn {
        background-color: transparent;
      }

      video {
        max-height: calc(100vw / 1.777777);
        background: black;
      }
    }
  }
}

.player-large.player-ui-hidden {
  .main-nav__logo {
    opacity: 0;
  }

  @include breakpoints.breakpoint(min-desktop) {
    &:not(.player-brandingday) {
      .layout-wrapper__sidenav {
        opacity: 0;
      }
    }
  }

  &:not(.player-listing-open) {
    @include breakpoints.breakpoint(max-tablet-landscape) {
      .player-listing__btn,
      .player-listing__wrapper,
      .jumpseek-container,
      .player-header,
      .btn__next-episode {
        display: none;
      }
    }

    @include breakpoints.breakpoint(max-tablet-portrait) {
      .player[data-fullscreen="true"] {
        .player-listing__btn,
        .player-listing__wrapper,
        .jumpseek-container,
        .player-header,
        .btn__next-episode {
          display: none;
        }
      }
    }

    @include breakpoints.breakpoint(min-desktop) {
      .player-listing__btn,
      .player-listing__wrapper,
      .jumpseek-container,
      .player-header,
      .btn__next-episode {
        display: none;
      }
    }
  }

  &.player-listing-open {
    @media (orientation: portrait) {
      .player-listing__btn,
      .player-listing__wrapper,
      .jumpseek-container,
      .player-header,
      .btn__next-episode {
        display: none;
      }
    }
  }
}

// Bigger clickbox on seekbar
.bmpui-seekbar-backdrop {
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1em;
    width: 100%;
  }

  &::before {
    top: -1em;
  }

  &::after {
    top: 0;
  }
}

.bmpui-ui-ads-status {
  display: none;
}
