@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';

body {
  &.layout-init {
    @media (prefers-color-scheme: dark) {
      &::before {
        filter: colors.$darkmode-body-filter;
      }
    }

    main {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100vw;
      height: 100vh;
    }
  }

}
