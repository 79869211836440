@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/typography';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/mixins/animations';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/layout/helpers';

:root {
  --epg-background: #{rgba(colors.$color-white, .4)};
  --epg-station-background: #{colors.$color-white};
  --epg-station-live-btn-background: #{colors.$color-white};
  --epg-station-live-timeline-wrapper-background: #{colors.$translucent-white};
  --epg-slider-inner-background: #{rgba(colors.$color-white, .4)};
  --epg-ngx-slider-bubble-background: #{colors.$color-white};
  --epg-loading-bg-filter: opacity(1);

  &.theme--dark {
    --epg-background: #{colors.$darkmode-dark};
    --epg-station-background: #{colors.$color-gray-dark};
    --epg-station-live-btn-background: #{colors.$color-gray-dark};
    --epg-station-live-timeline-wrapper-background: #{rgba(colors.$color-gray-dark, colors.$opaque-alpha)};
    --epg-slider-inner-background: #{colors.$color-gray-dark};
    --epg-ngx-slider-bubble-background: #{colors.$color-gray-darker};
    --epg-loading-bg-filter: opacity(0.3);
  }
}

.tabbar-epg {
  @include breakpoints.breakpoint(min-desktop) {
    .btn__select {
      width: 200px;
    }
  }
}

.layout-wrapper--scrolled {
  .epg__slider {
    z-index: variables.$z-index-root + 5;
    position: fixed;
    top: calc(#{variables.$tabbar-height} + #{sizes.$size-tiny});
    left: variables.$tabbar-height;
    right: sizes.$size-large;

    &-inner {
      background-color: var(--tabbar-scrolled-bg);
      box-shadow: borders.$shadow-large;
    }

    .ngx-slider {
      .ngx-slider-tick,
      .ngx-slider-bar {
        background-color: colors.$color-gray-light;
      }
    }
  }
}

.epg {
  background-color: var(--epg-background);
  border-radius: sizes.$size-base sizes.$size-base 0 0;
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  &__slider {
    position: relative;
    height: variables.$slider-height;

    &-inner {
      background-color: var(--epg-slider-inner-background);
      padding: 0 sizes.$size-base sizes.$size-base;
      border-radius: sizes.$size-base;
      height: 4.5rem;
    }

    .ngx-slider {
      .ngx-slider-tick,
      .ngx-slider-bar {
        background-color: colors.$color-white;
      }

      .ngx-slider-bubble {
        font-size: sizes.$size-small;
        z-index: 11;
        border-radius: sizes.$size-mini;
        color: var(--item-color-text) !important;
        background-color: var(--epg-ngx-slider-bubble-background) !important;
      }

      .ngx-slider-ticks-values-under .ngx-slider-tick-value {
        bottom: auto;
        top: -27px;
        font-size: 12px;
        display: none;
      }

      .ngx-slider-tick:nth-child(4n + 1) {
        .ngx-slider-tick-value {
          display: inline-block;
        }
      }

      .ngx-slider-tick:last-child {
        .ngx-slider-tick-value {
          display: inline-block;
        }
      }

      .ngx-slider-tick:hover {
        .ngx-slider-tick-value {
          display: inline-block;
        }
      }
    }
  }

  &__timeline {
    position: relative;
    display: flex;
    align-items: stretch;
    top: 0;
    z-index: variables.$z-index-root + 1;
    width: max-content;
    border-radius: sizes.$size-base sizes.$size-base 0 0;
    box-sizing: border-box;

    &-wrapper {
      position: relative;
      width: calc(25 * 60 * #{variables.$minute-pixels});
      border-radius: sizes.$size-base sizes.$size-base 0 0;
      background-color: var(--epg-station-live-timeline-wrapper-background);
    }

    &__live-indicator {
      animation: indicator_transform 86400s infinite linear;
      position: absolute;
      width: 1px;
      height: variables.$epg-lane-height * 300;
      top: 3rem;
      left: 0;
      background-color: colors.$color-red;

      &__flag {
        position: absolute;
        top: calc(-#{sizes.$size-base} - 2px);
        font-size: sizes.$size-small;
        line-height: sizes.$size-small;
        color: colors.$color-white;
        padding: 3px;
        background-color: colors.$color-red;
      }
    }

    &__live-btn {
      position: absolute;
      z-index: variables.$z-index-root + 3;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: variables.$epg-lane-height;
      height: variables.$timeline-height;
      background-color: var(--epg-station-live-btn-background);
      border-radius: sizes.$size-base 0 0 0;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -(sizes.$size-small);
        width: sizes.$size-small;
        height: 100%;
        background: transparent;
        background: linear-gradient(90deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,0) 100%);
      }
    }

    li {
      display: flex;
      align-items: center;
      min-width: calc(#{variables.$minute-pixels} * 60);
      min-height: calc(#{variables.$timeline-height} - 1px);
      background-image: url("../../images/line.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 1px 10px;

      &:last-child {
        max-width: 5rem;
        min-width: 5rem;
        background-image: none;
      }

      span {
        transform: translateX(-50%);
      }
    }
  }

  &__scroll-view {
    padding-left: variables.$epg-lane-height;
    overflow-x: auto;
    overflow-y: hidden;
    @include helpers.niceScrollbar();
  }

  .epg__station {
    display: flex;
    align-items: center;
    width: calc(#{variables.$minute-pixels} * 60 * 25);
    height: variables.$epg-lane-height;
    background-image: url("../../images/line-600.png");
    background-repeat: repeat;
    background-position-x: calc(#{variables.$minute-pixels} * 60px);

    &--loading {
      display: none;
    }

    &__info {
      text-align: center;
      padding: sizes.$size-large 0 30vh 0;
      background-color: var(--epg-station-background);
    }

    &__loading {
      width: calc(#{variables.$minute-pixels} * 60 * 25);
      @include animations.animation-pulse();

      &__row {
        background-image: url("../../images/line-600.png");
        background-repeat: repeat;
        background-position-x: calc(#{variables.$epg-lane-height});

        &-bg {
          height: variables.$epg-lane-height;
          background-image: url("../../images/epg_station_row_loading.svg");
          background-repeat: repeat-x;
          background-position-x: sizes.$size-tiny;
          background-position-y: sizes.$size-tiny;
          background-size: auto 60px;
          animation-name: pulse;
          animation-duration: 2s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          filter: var(--epg-loading-bg-filter) !important;
        }

        .epg__station__logo {
          margin-top: -(variables.$epg-lane-height);
        }
      }
    }

    &__logo {
      position: absolute;
      z-index: variables.$z-index-root + 4;
      left: 0;
      width: variables.$epg-lane-height;
      height: variables.$epg-lane-height;
      padding: sizes.$size-tiny;
      margin-top: -(sizes.$size-tiny);
      background-color: var(--epg-station-background);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -(sizes.$size-small);
        width: sizes.$size-small;
        height: 100%;
        background: transparent;
        background: linear-gradient(90deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,0) 100%);
      }

      img {
        width: auto;
        height: calc(#{variables.$epg-lane-height} - #{sizes.$size-base});
      }
    }

    &__lane {
      display: flex;
    }
  }
}

@include breakpoints.breakpoint(max-tablet) {
  .epg {
    &__slider {
      display: none;
    }
  }
}

.mobile {
  @include breakpoints.breakpoint(only-mobile) {

    .epg__scroll-view {
      padding-left: variables.$epg-lane-height-mobile;
    }

    .epg__station {
      height: variables.$epg-lane-height-mobile;
      background-size: calc(#{variables.$minute-pixels-mobile} * 60);
      margin-left: 0;
      width: calc(#{variables.$minute-pixels-mobile} * 60 * 25);

      .epg-broadcast {
        padding: sizes.$size-mini sizes.$size-small;

        .h3 {
          font-size: typography.$font-size-small;
        }
      }

      &__loading {
        width: calc(#{variables.$minute-pixels-mobile} * 60 * 25);
        background-size: calc(#{variables.$minute-pixels-mobile} * 60);

        &__row {
          margin-left: 0;
          background-image: url("../../images/line-600.png");
          background-repeat: repeat;
          background-position-x: 0;
          background-size: calc(#{variables.$minute-pixels-mobile} * 60);

          &-bg {
            background-size: auto 43px;
            height: variables.$epg-lane-height-mobile;
          }

          .epg-station__logo {
            margin-top: -(variables.$epg-lane-height-mobile);
          }
        }
      }

      &__logo {
        height: variables.$epg-lane-height-mobile;
        width: variables.$epg-lane-height-mobile;
        margin-top: -(sizes.$size-mini);

        img {
          height: calc(#{variables.$epg-lane-height-mobile} - #{sizes.$size-base});
        }
      }
    }

    .epg__timeline {
      margin-left: 0;

      &__live-indicator {
        animation: indicator_transform_mobile 86400s infinite linear;
      }

      &__live-btn .btn__primary {
        background-color: transparent;
        color: colors.$color-blue;
      }

      &-wrapper {
        width: calc(25 * 60 * #{variables.$minute-pixels-mobile});
      }

      li {
        min-width: calc(#{variables.$minute-pixels-mobile} * 60);
      }

      &__live-btn {
        width: variables.$epg-lane-height-mobile;
      }
    }
  }
}

@keyframes indicator_transform {
  0% {
    transform: translateX(0px)
  }
  100% {
    transform: translateX(14400px)
  }
}

@keyframes indicator_transform_mobile {
  0% {
    transform: translateX(0px)
  }
  100% {
    transform: translateX(5760px)
  }
}
