/**
 * @see https://css-tricks.com/naming-media-queries/
 * @see https://www.mediaevent.de/css/breakpoints.html
 */

$mobile-max: 480px;
$tablet-max: 896px;
$desktop-max: 1400px;

$tablet-min: $mobile-max + 1;
$desktop-min: $tablet-max + 1;
$desktop-large-min: $desktop-max + 1;

@mixin breakpoint($point) {
  @if $point == only-mobile {
    @media (max-width: $mobile-max) {
      @content;
    }
  } @else if $point == min-tablet {
    @media (min-width: $tablet-min) {
      @content;
    }
  } @else if $point == only-tablet {
    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      @content;
    }
  } @else if $point == max-tablet {
    @media (max-width: $tablet-max) {
      @content;
    }
  } @else if $point == max-tablet-landscape {
    @media (max-width: $tablet-max) and (orientation: landscape) {
      @content;
    }
  } @else if $point == max-tablet-portrait {
    @media (max-width: $tablet-max) and (orientation: portrait) {
      @content;
    }
  } @else if $point == min-desktop {
    @media (min-width: $desktop-min) {
      @content;
    }
  } @else if $point == max-desktop {
    @media (max-width: $desktop-max) {
      @content;
    }
  } @else if $point == min-desktop-large {
    @media (min-width: $desktop-large-min) {
      @content;
    }
  }
}

@mixin full-width() {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
