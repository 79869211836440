
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/mixins/theme';
@use 'src/assets/scss/layout/helpers';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/settings/transitions';

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --app-bg: url("https://static.teleboy.ch/web/blur-bg.jpg") top left / cover no-repeat fixed;


  &.theme--dark {
    --app-bg: #{colors.$color-gray-darker};
  }
}

body {
  margin: 0;

  @include helpers.niceScrollbar();

  background: var(--app-bg);
  color: var(--body-color);

  &.mobile {
    .teaser-item--top {
      .ratio__4x1 {
        padding-bottom: 42%;
      }
    }
  }

  &.layout-init {
    &:before {
      display: none;
    }
  }

  &.search-open,
  &.popup-open,
  &.bottomsheet-open,
  &.player-large {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
  }

  &.popup-open,
  &.search-open {
    @include breakpoints.breakpoint(max-tablet) {
      .main-nav__logo {
        opacity: 0;
      }
    }
  }

  &.search-open {
    padding-right: 11px;
  }

  @include breakpoints.breakpoint(only-mobile) {
    -webkit-text-size-adjust: 100%
  }
}

.ng-lazyloading {
  background-color: transparent;
  opacity: 0;
}

.ng-lazyloaded {
  transition: transitions.$transition-all-fast;
  opacity: 1;
}

.scroll-btn-horizontal {
  height: 3rem;
  overflow: hidden;
  margin: 0 (-(sizes.$size-base));

  > ul {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: sizes.$size-base;

    > li {
      margin-right: sizes.$size-tiny;
      display: inline-block;

      &:first-child {
        margin-left: sizes.$size-base;
      }
    }
  }
}

.filter-col {
  display: flex;
  gap: sizes.$size-medium;
  flex-direction: row-reverse;

  &__left {
    flex: 3;
    min-width: 0;
  }

  &__right {
    flex: 1;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    padding-top: 2.2em;

    @include breakpoints.breakpoint(max-tablet) {
      transition: transitions.$transition-all-fast;
      position: fixed;
      bottom: -100%;
      width: 100%;
      z-index: variables.$z-index-root + 10;
    }
  }
}

.layout-wrapper {
  display: flex;

  &__sidenav {
    z-index: variables.$z-index-navi;
  }

  &__content {
    padding-top: 6rem;
    margin: 0 sizes.$size-large 30rem;
    flex: 1;
    min-width: 0;

    @include breakpoints.breakpoint(max-tablet) {
      margin: 0 sizes.$size-small 30rem;
      padding-top: 5rem;
    }
  }

  &--scrolled {

    .main-nav__inner {
      transition: transitions.$transition-all-fast;
      padding-top: 7rem !important;
    }

    .main-nav__logo {
      margin-right: sizes.$size-base;

      @include breakpoints.breakpoint(max-tablet) {
        left: calc(100vw + 1rem) !important;
      }
    }
  }
}

.view-epg {
  .layout-wrapper__content {
    margin-bottom: 0;
  }
}

.tb-logo {
  content: url("https://static.teleboy.ch/shared/logos/logo-teleboy-negative.svg");
  max-width: 80%;
  max-height: 80vh;
  height: 100%;

  @include breakpoints.breakpoint(max-tablet-portrait) {
    content: url("https://static.teleboy.ch/shared/logos/logo-teleboy-vertical-negative.svg");
    width: 150px;
  }
}
