@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';

.mat-mdc-snack-bar-container {
  &.tb-snackbar {
    background-color: colors.$opaque-white;
    box-shadow: borders.$shadow-small;
    color: colors.$color-white;
    text-align: center;

    .mat-mdc-simple-snack-bar {
      justify-content: center;
    }

    &--success {
      background-color: rgba(colors.$color-green, colors.$opaque-alpha);
    }

    &--warning {

    }

    &--error {
      background-color: rgba(colors.$color-red, colors.$opaque-alpha);
    }
  }
}
