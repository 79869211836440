@use "../../../assets/scss/vars/borders";
@use "../../../assets/scss/vars/sizes";
@use "../../../assets/scss/vars/colors";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border: none;
  border-radius: borders.$border-radius-rounded;
  background-color: var(--button-bg) !important;
  background-clip: padding-box;
  padding: sizes.$size-tiny sizes.$size-base;

  color: var(--button-color) !important;;
  font-size: sizes.$size-base !important;
  text-decoration: none !important;
  line-height: 1em;
  white-space: nowrap;

  cursor: pointer;

  transform: scale(1);
  transition: all .1s ease-out;

  &:not(.btn__select) {
    font-weight: bold !important;
  }

  @at-root a#{&} {
    color: var(--button-color) !important;
  }

  @media (hover: hover) {
    &:hover,
    &:focus:focus-visible {
      &:not(.btn--bordered) {
        outline: none;
      }

      @at-root a#{&} {
        text-decoration: none!important;;
      }

      &:not(.btn__select, .btn__link-like) {
        transform: scale(1.1);
      }

      &:not(.btn__primary, .btn__secondary, .btn__filter, .btn__link-like, .btn--transparent-dark, .btn--active, .btn__black) {
        background-color: var(--button-bg-hover) !important;
      }
    }
  }

  .icon {
    font-size: sizes.$size-medium;
  }

  &--sm {
    padding: sizes.$size-micro sizes.$size-small;
    font-size: sizes.$size-small !important;

    .icon {
      font-size: sizes.$size-base;
    }
  }

  &--lg {
    padding: sizes.$size-base sizes.$size-medium;
    font-size: sizes.$size-medium !important;

    .icon {
      font-size: sizes.$size-xxlarge;
    }
  }

  &--xl {
    padding: sizes.$size-base sizes.$size-large;
    font-size: sizes.$size-medium !important;
  }

  &--busy {
    &::after {
      content: "";
      display: block;
      animation: rotate .8s infinite linear;
      border: 2px solid colors.$color-white!important;
      border-right-color: colors.$color-blue!important;
      border-radius: borders.$border-radius-circle;
      width: 1em;
      height: 1em;
      margin-left: sizes.$size-small;
    }
  }

  &--circle {
    padding: sizes.$size-mini;

    &.btn__icon.btn--busy {
      border-color: transparent;
      position: relative;

      &::after {
        position: absolute;
        border-width: 1px;
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }

  &__primary {
    color: colors.$color-white!important;
    background-color: colors.$color-blue!important;

    @at-root a#{&} {
      color: colors.$color-white!important;
    }
  }

  &__secondary {
    background-color: var(--button-bg-secondary) !important;
  }

  &__danger {
    background-color: colors.$color-red!important;
  }

  &__black {
    color: colors.$color-white!important;
    background-color: colors.$color-black!important;

    @at-root a#{&} {
      color: colors.$color-white!important;
    }
  }

  &__link-like {
    background-color: transparent!important;
    padding: 0;
    font-weight: normal!important;
    color: colors.$color-blue!important;
    text-align: left;

    &:hover,
    &:focus {
      transform: none;
      text-decoration: underline;
    }
  }

  &__link {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: colors.$color-blue;
    font-size: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
      outline: none;
    }
  }

  &__select {
    &:focus {
      box-shadow: borders.$shadow-small;
      outline: 1px solid colors.$color-blue!important;
      outline-offset: -1px;
    }
  }

  &__icon {
    padding-top: sizes.$size-mini;
    padding-bottom: sizes.$size-mini;
  }

  &__scroll-top {
    display: none;
  }

  &--active {
    &:not(.btn__primary) {
      color: colors.$color-white!important;
      background-color: var(--button-bg-focus)!important;
    }
  }

  &--bordered {
    outline: 1px solid colors.$color-gray-light;
    outline-offset: -1px;
  }

  &--opaque {
    color: var(--button-color) !important;
    background-color: var(--button-bg-opaque) !important;
  }

  &__list {
    &-v,
    &-h {
      display: flex;
      gap: sizes.$size-small;
    }

    &-v {
      flex-direction: column;
      align-items: stretch;

      &--stretch {
        align-items: stretch;

        .btn {
          width: 100%;
        }
      }

      &--center {
        align-items: center;
      }
    }

    &-h {
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      &--center {
        justify-content: center;
      }

      &--apart {
        justify-content: space-between;
      }

      &--evenly {
        justify-content: space-around;

        > * {
          flex: 1;
        }
      }

      &--end {
        justify-content: flex-end;
      }
    }
  }
}

.layout-wrapper-scrolled {
  .btn__scroll-top {
    display: inline-block;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
