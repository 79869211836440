@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  &.bullet-list {
    list-style: disc;
    margin-bottom: sizes.$size-base;
    padding-left: sizes.$size-base;

    li {
      line-height: 1.5;
    }
  }
}

details {
  position: relative;
  cursor: text;
  margin-bottom: sizes.$size-base;

  > p:last-child {
    margin-bottom: 0;
  }

  &[open] {
    summary {
      display: none;
    }
  }

  summary {
    cursor: pointer;
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .read-more {
      color: colors.$color-blue;
    }
  }
}
