@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'src/assets/scss/mixins/animations';

.ghost-listing {
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;

  .card {
    background: var(--item-bg);
    box-shadow: none;
  }
}

@include animations.animation-pulse();
