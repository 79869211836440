@use '@angular/material' as mat;

@use 'node_modules/@teleboy/web.ui/assets/scss/theme/vars';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/typography';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'node_modules/@teleboy/web.ui/assets/scss/elements/card';
@use 'node_modules/@teleboy/web.ui/assets/scss/utils';

@use 'src/assets/scss/layout/text';
@use 'src/assets/scss/layout/tabbar';
@use 'src/assets/scss/layout/popup' as customPopup;
@use 'src/assets/scss/layout/ui';
@use 'src/assets/scss/layout/helpers';
@use 'src/assets/scss/layout/responsive';

@use 'src/assets/scss/elements/card' as customCard;
@use 'src/assets/scss/elements/tooltip';
@use 'src/assets/scss/elements/snackbar';
@use 'src/assets/scss/elements/swiper';
@use 'src/assets/scss/elements/player';
@use 'src/assets/scss/elements/player-brandingday';
@use 'src/assets/scss/elements/player-bitmovin';
@use 'src/assets/scss/elements/epg';
@use 'src/assets/scss/elements/popup';


@use 'src/assets/scss/elements/bottomsheet';
@use 'src/assets/scss/elements/broadcast-item';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/init';
@use 'src/assets/scss/layout/ghosts';
@use 'layout/base';
@use 'src/assets/scss/settings/transitions';

@use 'node_modules/@teleboy/web.ui/assets/scss/elements/form';
@use 'node_modules/@teleboy/web.ui/assets/scss/elements/button';
@use 'node_modules/@teleboy/web.ui/assets/scss/elements/dropdown';
@use 'node_modules/@teleboy/web.ui/assets/scss/elements/switch';
@use 'node_modules/@teleboy/web.ui/assets/scss/elements/badge';

$my-custom-level: mat.m2-define-typography-config(
  $font-family: typography.$font-family-body
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($my-custom-level);`
@include mat.all-component-typographies($my-custom-level);
@include mat.elevation-classes();
@include mat.app-background();


:root {
  font-size: typography.$font-size;

  @include breakpoints.breakpoint(max-tablet) {
    font-size: typography.$font-size-mobile;
  }
}

body {
  font-family: typography.$font-family-body;
  line-height: typography.$line-height;

  &.player-large {
    // iOS safe area colors fix.
    @media (orientation: landscape) {
      background-color: colors.$color-black;
    }
  }
}
