:root {
  --tb-color-white: #fff;
  --tb-color-blue: #1fbbe8;
  --tb-color-gray: #7f7f7f;
}

// BRAND
$color-blue: #1DBBE9;
$color-blue-dark: #0E6783;
$color-blue-light: #D0F4FF;

// GRAY
$color-gray-very-light: #F6F6F6;
$color-gray-lighter: #E9E9E9;
$color-gray-light: #D2D2D2;
$color-gray: #AFAFAF;
$color-gray-medium: #7F7F7F;
$color-gray-dark: #404040;
$color-gray-darker: #212121;
$color-gray-very-dark: #121212;

// BASE
$color-white: #ffffff;
$color-black: #000000;
$color-text: $color-gray-very-dark;

// ACCENTS
$color-green: #53C555;
$color-green-dark: #235424;
$color-green-light: #B3DFAE;

$color-red: #EB1738;
$color-red-dark: #6D0A1A;
$color-red-light: #FF918D;

$color-orange: #F89406;
$color-yellow: #FFD700;
$color-magenta: #EF0053;


// TRANSPARENCY
$opaque-alpha: .9;
$translucent-alpha: .7;
$transparent-alpha: .2;

$opaque-white: rgba($color-white, $opaque-alpha);
$opaque-blue: rgba($color-blue, $opaque-alpha);
$opaque-blue-dark: rgba($color-blue-dark, $opaque-alpha);

$translucent-white: rgba($color-white, $translucent-alpha);
$translucent-gray: rgba($color-gray-very-light, $translucent-alpha);
$translucent-black: rgba($color-black, $translucent-alpha);

$transparent-white: rgba($color-white, $transparent-alpha);
$transparent-black: rgba($color-black, $transparent-alpha);

// COLOR FILTERS
$filter-white: invert(100%) sepia(0%) saturate(7500%) hue-rotate(191deg) brightness(110%) contrast(111%);



// DARKMODE
$darkmode-dark: $color-gray-very-dark;
$darkmode-card-bg-color: #2E2E2E; // custom
$darkmode-tabbar-bg-color: $color-gray-dark;
$darkmode-btn-bg-color: $color-gray-dark;
$darkmode-text-color: $color-gray-light;
$darkmode-body-filter: brightness(0.1);
