@use "../../../assets/scss/vars/sizes";

@mixin niceScrollbar() {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: sizes.$size-small;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: sizes.$size-small;
    border: 3px solid var(--scrollbarBG);
  }
}
