@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/layout/helpers';

:root {
  --player-play-icon: #{url('/assets/images/actions/action-play.svg')};
  --player-pause-icon: #{url('/assets/images/actions/action-pause.svg')};

  &.theme--dark {
    --player-play-icon: #{url('/assets/images/actions/action-play-negative.svg')};
    --player-pause-icon: #{url('/assets/images/actions/action-pause-negativ.svg')};
  }
}

// Bitmovin UI
.player-wrapper {

  .bitmovinplayer-container,
  .bitmovinplayer-ad-container {
    @include breakpoints.breakpoint(max-tablet-portrait) {
      color: colors.$color-text;
    }
  }

  .bmpui-ui-selectbox {
    background-color: colors.$color-gray-very-dark;
    margin: 0 !important;
    padding: sizes.$size-tiny;
    font-size: sizes.$size-small;
  }

  .bmpui-ui-settings-panel {
    z-index: variables.$z-index-root * 800;
  }

  .bmpui-ui-seekbar .bmpui-seekbar,
  .bmpui-ui-volumeslider .bmpui-seekbar {
    .bmpui-seekbar-backdrop {
      background-color: colors.$color-white;
      border-radius: 4px;
    }

    .bmpui-seekbar-playbackposition {
      background-color: colors.$color-blue;
      border-radius: 4px;
    }

    .bmpui-seekbar-bufferlevel {
      border-radius: 4px;
    }

    .bmpui-seekbar-playbackposition-marker {
      background-color: colors.$color-white;
      border: 0.2rem solid colors.$color-blue;
      height: sizes.$size-medium;
      width: sizes.$size-medium;
    }
  }

  .bmpui-controls-hidden.bmpui-ui-uicontainer {
    .bmpui-ui-hugeplaybacktogglebutton {
      background-color: transparent;
      transition: background-color 300ms;
    }
  }

  @include breakpoints.breakpoint(min-desktop) {
    .bmpui-controls-shown.bmpui-ui-uicontainer {
      .bmpui-ui-hugeplaybacktogglebutton {
        background-color: rgba(0,0,0,.2);
        transition: background-color 300ms;
      }
    }
  }

  .bmpui-ui {
    &-titlebar,
    &-watermark,
    &-fullscreentogglebutton {
      display: none;
    }

    &-hugeplaybacktogglebutton {
      padding: 0;

      @include breakpoints.breakpoint(min-desktop) {
        background-color: colors.$transparent-black;
        transition: background-color 300ms;
      }

      @media (orientation: landscape) {
        background-color: colors.$transparent-black;
        transition: background-color 300ms;
      }

    }

    &-seekbar-label {
      .bmpui-seekbar-label-inner {
        border-bottom: none;

        &>.bmpui-container-wrapper .bmpui-seekbar-label-metadata {
          background: colors.$color-white;
          color: colors.$color-text;
          border-radius: sizes.$size-base;

          .bmpui-seekbar-label-title {
            display: none;
          }
        }
      }
    }

    &-volumetogglebutton.bmpui-unmuted {
      background-image: url('/assets/images/actions/action-mute-on.svg') !important;
    }

    &-volumetogglebutton.bmpui-muted {
      background-image: url('/assets/images/actions/action-mute-off.svg') !important;
    }

    &-playbacktogglebutton.bmpui-on {
      background-image: var(--player-pause-icon) !important;
      background-size: 140%;
    }

    &-playbacktogglebutton.bmpui-off {
      background-image: var(--player-play-icon) !important;
      background-size: 140%;
    }

    &-settingstogglebutton {
      background-image: url('/assets/images/actions/action-settings.svg') !important;
    }

    &-volumetogglebutton.bmpui-unmuted,
    &-volumetogglebutton.bmpui-muted,
    &-playbacktogglebutton.bmpui-on,
    &-playbacktogglebutton.bmpui-off,
    &-settingstogglebutton {
      filter: colors.$filter-white;
    }

    &-controlbar .bmpui-controlbar-bottom>.bmpui-container-wrapper .bmpui-ui-volumeslider {
      width: sizes.$size-base * 8;
      max-width: 20%;
    }

    .bmpui-ui-playbacktogglebutton {
      display: none;
    }

    &-hugeplaybacktogglebutton {
      .bmpui-image {
        background-image: none !important;
      }

      &::before {
        content: "";
        background-image: var(--player-play-icon);
        background-color: var(--button-bg);
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
        width: sizes.$size-base * 7;
        height: sizes.$size-base * 7;
        border-radius: 50%;
        background-size: contain;

        @include breakpoints.breakpoint(max-tablet) {
          width: sizes.$size-base * 5;
          height: sizes.$size-base * 5;
        }

        @include breakpoints.breakpoint(only-mobile) {
          width: sizes.$size-base * 4;
          height: sizes.$size-base * 4;
        }
      }

      &.bmpui-on::before {
        transition: visibility 0s;
        visibility: hidden;
        background-image: var(--player-pause-icon);
      }

      &.bmpui-off::before {
        visibility: visible;
        transition: visibility 0s;
      }
    }

    &-skin-ads {
      .bmpui-ui-ads-status {
        top: revert !important;
        bottom:  sizes.$size-base * 5 !important;
        left: sizes.$size-base !important;
        border-radius: sizes.$size-tiny;
        padding: sizes.$size-tiny !important;

        @include breakpoints.breakpoint(only-mobile) {
          left: sizes.$size-base !important;
          bottom: sizes.$size-tiny !important;
          right: auto !important;
          width: auto !important;
        }
      }
    }
  }
}

.player-large:not(.player-listing-open) {
  @include breakpoints.breakpoint(max-tablet-portrait) {
    .player-wrapper .player:not([data-fullscreen="true"]) {
      .bmpui-ui-uicontainer {

        .bmpui-ui-settings-panel {
          position: fixed;
          top: auto;
          bottom: variables.$main-tab-nav-height;
          left: 50%;
          transform: translateX(-50%);
        }

        .bmpui-ui-controlbar {
          background: none;
          margin-bottom: sizes.$size-base * 13;

          .bmpui-controlbar-bottom {
            position: relative;
            bottom: -(sizes.$size-base * 12.5);
          }

          &.bmpui-ui-skin-smallscreen {
            margin-bottom: sizes.$size-base * 10;
          }

          &.bmpui-hidden {
            opacity: 1 !important;
          }

          .bmpui-ui {
            &-playbacktimelabel {
              color: var(--item-color-text);
            }

            &-playbacktogglebutton {
              display: none;
            }

            &-volumetogglebutton.bmpui-unmuted,
            &-volumetogglebutton.bmpui-muted,
            &-playbacktogglebutton.bmpui-on,
            &-playbacktogglebutton.bmpui-off,
            &-settingstogglebutton {
              filter: none;
            }
          }
        }

        .bmpui-ui-hugeplaybacktogglebutton {
          &.bmpui-on::before {
            visibility: visible !important;
          }

          &::before {
            bottom: 18%;
            transform: translate(-50%, 50%);
          }
        }
      }
    }
  }
}

// Marker Range CSS hack - check open issue on Bitmovin for clickable range markers and seeking with issuer ui
.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  z-index: 2;
}

.bmpui-seekbar-playbackposition-marker {
  z-index: 3;
}

.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers,
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers {
  height: 5px;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;


  .bmpui-tb-timeline-marker {
    @include breakpoints.breakpoint(max-tablet) {
      height: .3125em;
    }
    &:before {
      content: '';
      background-color: colors.$color-white;
      position: absolute;
      width: 100vh;
      left: 2px;
      height: 5px;

      @include breakpoints.breakpoint(max-tablet) {
        height: .3125em;
      }
    }

    &.bmpui-tb-timeline-marker__start,
    &.bmpui-tb-timeline-marker__ad-end,
    &.bmpui-tb-timeline-marker__end {
      &:before {
        background-color: colors.$color-white;
      }
    }

    &.bmpui-tb-timeline-marker__start {
      background-color: colors.$color-blue-dark;
    }

    &.bmpui-tb-timeline-marker__end {
      z-index: 1;
      background-color: colors.$color-blue-dark;
    }

    &.bmpui-tb-timeline-marker__ad-start {
      &:before {
        background-color: colors.$color-gray-medium;
      }
    }
  }
}

.player-mini {
  .bmpui-ui-ads-status {
    display: none;
  }
}


// AD UI
.ad-ui {
  &__close {
    position: absolute;
    z-index: 1;
    top: sizes.$size-base;
    right: sizes.$size-base;

    @include breakpoints.breakpoint(max-tablet-portrait) {
      display: none;
    }
  }

  &__container {
    align-items: flex-end !important;
    z-index: 1;
    pointer-events: none;

    @include breakpoints.breakpoint(max-tablet-portrait) {
      align-items: flex-start !important;
    }

    .tbui__ad-info {
      position: absolute;
      z-index: 1;
      border-radius: borders.$border-radius-large;
      background: colors.$translucent-black;
      padding: sizes.$size-tiny sizes.$size-small;
      margin: sizes.$size-base;
      color: colors.$color-white;
      font-size: sizes.$size-base;
      bottom: sizes.$size-xxlarge;
      pointer-events: all;

      @include breakpoints.breakpoint(max-tablet-portrait) {
        bottom: 0;
      }

      &__ad-counter::after {
        content: '• ';
        color: colors.$color-blue;
        padding: 0 sizes.$size-mini;
      }

      &__skip__button {
        font-size: sizes.$size-base !important;
      }
    }

    .action__button {
      padding: 0 sizes.$size-mini;
      cursor: pointer;
      text-decoration: none;
    }
  }

  &__blackened {
    background-color: colors.$color-black;
  }
}
