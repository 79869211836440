@use "../../../assets/scss/vars/sizes";
@use "../../../assets/scss/vars/colors";

.badge {
  display: inline-block;
  padding: sizes.$size-tiny sizes.$size-tiny;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: sizes.$size-micro;
  font-size: sizes.$size-base * .875;
  color: colors.$color-text;
  background-color: colors.$translucent-white;

  &--circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: sizes.$size-medium;
    height: sizes.$size-medium;
    border-radius: 50%;
    padding: 0;
  }

  &__primary {
    background-color: colors.$color-blue;
    color: colors.$color-white;
  }

  &__secondary,
  &__new,
  &__inactive,
  &__pending,
  &__default {
    background: colors.$color-gray-lighter;
    color: colors.$color-gray-dark;
  }

  &__lost,
  &__rejected,
  &__delayed,
  &__error {
    background: colors.$color-red;
    color: colors.$color-white;
  }

  &__canceled,
  &__review
  {
    background: colors.$color-orange;
    color: colors.$color-white;
  }

  &__created,
  &__ended,
  &__ending,
  &__warning,
  &__open {
    background: colors.$color-orange;
    color: colors.$color-white;
  }

  &__ready,
  &__synced,
  &__active,
  &__payed,
  &__success {
    background: colors.$color-green;
    color: colors.$color-white;
  }

  &--sm {
    padding: sizes.$size-mini sizes.$size-tiny;
    border-radius: sizes.$size-mini;
    font-size: sizes.$size-small;
  }

  @media print {
    display:none;
  }
}
