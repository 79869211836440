@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'src/assets/scss/mixins/breakpoints';

.card {
  background-color: var(--item-bg);
  box-shadow: borders.$shadow-small;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid colors.$color-gray-light;
    padding: sizes.$size-base;

    h2 {
      margin-bottom: 0;
    }
  }

  &__body {
    padding: sizes.$size-base;
  }

  &--rounded {
    border-radius: borders.$border-radius;

    &--hidden {
      overflow: hidden;
    }
  }

  &--translucent {
    background-color: colors.$translucent-white;
  }

  &--transparent {
    background-color: colors.$transparent-white;
  }

  &--gray {
    background-color: var(--broadcast-details-gray-bg) !important;
  }

  &--flat {
    box-shadow: none;
  }
}

.grid {
  display: grid;
  grid-gap: sizes.$size-base;
  grid-template-columns: 100%;

  &__sliders {
    grid-gap: sizes.$size-large;
    position: relative;
  }

  &.grid__tiles {
    @include breakpoints.breakpoint(only-tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoints.breakpoint(min-desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include breakpoints.breakpoint(min-desktop-large) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  @include breakpoints.breakpoint(only-tablet) {
    &__sliders {
      grid-gap: sizes.$size-medium;
    }
  }

  @include breakpoints.breakpoint(only-mobile) {
    &__sliders {
      grid-gap: sizes.$size-tiny;
    }
  }
}
