// @see https://xd.adobe.com/view/699dc489-6182-4f2b-8c1e-4bf06c0d27ca-3cce/screen/9e505dd7-75bc-46db-befd-ae291845ccfe/

@use "../../../assets/scss/vars/borders";
@use "../../../assets/scss/vars/sizes";

.tb-card {
  background: var(--item-bg);
  box-shadow: borders.$shadow-small;

  &__body {
    padding: sizes.$size-base;
  }

  &--shadowed {
    box-shadow: borders.$shadow;
  }
}
