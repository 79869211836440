.ratio {
  display: block;
  position: relative;
  overflow: hidden;
  height: 0;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__16x9 {
    padding-bottom: 56.25%;
  }

  &__4x1 {
    padding-bottom: 25%;
  }

  &__36x9 {
     padding-bottom: 25%;
   }

  &__1x1 {
    padding-bottom: 100%;
  }
}

.responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

/**
 * @see https://a11y-guidelines.orange.com/en/web/components-examples/accessible-hiding/
 */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
