@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/typography';

.mat-mdc-tooltip-show {
  background-color: colors.$color-gray-dark !important;
  border-radius: sizes.$size-micro;

  &.tb-tooltip {
    color: colors.$color-white;

    .mdc-tooltip__surface  {
      padding: sizes.$size-tiny sizes.$size-small;
      background-color: colors.$color-gray-dark;
      overflow: visible;
      white-space: pre-line;
      text-align: center;
      font-size: sizes.$size-base;
      max-width: 260px;
      color: white;
      line-height: 20px;
      border-radius: 10px;
    }

    &--blue {
      color: colors.$color-white;
      background-color: colors.$color-blue !important;
      box-shadow: borders.$shadow-small;

      .mdc-tooltip__surface {
        background-color: colors.$color-blue !important;
      }
    }

    &--right-large {
      margin-bottom: 10px !important;
      font-size: typography.$font-size !important;

      &::before {
        display: none !important;
      }
    }

    &::before {
      content: " " !important;
      position: absolute !important;
      top: 100% !important;
      left: 50% !important;
      right: 100% !important;
      margin-left: -5px !important;
      border-width: 7px !important;
      border-style: solid !important;
      border-color: colors.$color-gray-dark transparent transparent transparent !important;
    }
  }
}
