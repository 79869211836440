@use 'node_modules/swiper/swiper';
@use 'node_modules/swiper/modules/navigation/navigation';

@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/typography';
@use 'src/assets/scss/mixins/breakpoints';

.swiper-button-disabled {
  display: none;
}

.swiper-button-prev, .swiper-button-next {
  background-color: var(--button-bg);
  cursor: pointer;
  color: var(--button-color);
  padding: 0.3rem;
  border-radius: borders.$border-radius-circle;
  width: 44px;
  text-align: center;
  box-shadow: borders.$shadow-small;

  @include breakpoints.breakpoint(max-tablet) {
    display: none;
  }

  &::after {
    font-size: typography.$font-size;
  }
}

.swiper__container {
  margin-right: -(sizes.$size-large);
  margin-top: -(sizes.$size-tiny);

  &--broadcast {
    // min-height: 314px;
  }

  &--content {
    // min-height: 290px;
  }

  .swiper-wrapper {
    padding: sizes.$size-tiny 0 sizes.$size-tiny sizes.$size-small;
  }

  .swiper {
    margin-left: -(sizes.$size-small);
    padding-right: sizes.$size-large;
  }

  h2 {
    margin-bottom: sizes.$size-base;
  }

  @include breakpoints.breakpoint(max-tablet) {
    margin-bottom: sizes.$size-large;
    margin-right: -(sizes.$size-small);

    h2 {
      font-size: sizes.$size-base;
      margin-bottom: sizes.$size-small;
    }
  }
}
