@use "../../../assets/scss/vars/colors";
@use "../../../assets/scss/mixins/themes";

:root {
  --body-color: #{colors.$color-gray-dark};
  --body-color-secondary: #{colors.$color-gray-medium};

  --item-bg: #{colors.$color-white};
  --item-bg-focus: #{colors.$color-blue};
  --item-color-focus: #{colors.$color-white};
  --item-color-text: #{colors.$color-gray-very-dark};

  --button-bg: #{colors.$color-white};
  --button-bg-secondary: #{colors.$color-gray-light};
  --button-bg-opaque: #{rgba(colors.$color-white, colors.$translucent-alpha)};

  --button-color: #{colors.$color-gray-dark};
  --button-bg-focus: #{colors.$color-blue};
  --button-bg-hover: #{colors.$color-white};    // for buttons not primary and not secondary

  --form-input-bg: #{colors.$translucent-white};
  --form-input-border: #{colors.$color-gray-lighter};
  --form-input-color: #{colors.$color-gray-darker};

  --translucent-bg: #{colors.$translucent-white};

  /***********************************************
  Web.Player
  ***********************************************/

  --item-bg-focus-light: #{colors.$color-blue-light};

  // DROPDOWNS (keep the old implementation)
  --dropdown-bg: #{colors.$color-white};
  --dropdown-bg-hover: #{colors.$color-gray-very-light};
  --dropdown-color: #{colors.$color-text};
  --dropdown-border-bottom-color: #{colors.$color-gray-lighter};

  --popup-background: #{colors.$color-white};
  --popup-full-background: #D6D6E0;
  --popup-backdrop: #{rgba(colors.$color-gray-dark, colors.$opaque-alpha)};

  --card-hover: #{colors.$color-gray-very-light};

  --scrollbarBG: #e9e9e9;
  --thumbBG: #bbbbbb;

  // custom elements
  --broadcast-details-gray-bg: #{colors.$color-gray-very-light};
  --cpvr-provider-before: #{colors.$opaque-white};
  --lp-filter: blur(60px) contrast(30%) brightness(1.5);
  --tabbar-scrolled-bg: #{colors.$color-white};
  --tabbar-scrolled-button-bg: #{colors.$color-gray-light};
  --search-bg: #{colors.$color-gray-very-light};
  --player-body-filter: brightness(1);


  /***********************************************
  Web.SmartTV
  ***********************************************/
  --link-color: #{colors.$color-blue};

  --epg-bg-focus: #{colors.$color-blue};
  --epg-bg-live: #{colors.$color-blue-light};
  --epg-bg-stations: #{colors.$color-white};

  --player-ui-bg: #{colors.$color-gray-lighter};

  --icon-color: #{colors.$color-blue};
  --icon-color-focus: #{colors.$color-white};

  --pill-bg: #{colors.$color-gray-lighter};
  --pill-color: #{colors.$color-gray-medium};

  --dialog-bg: #{colors.$color-gray-lighter};

  --separator-color: #{colors.$color-gray-lighter};

  --state-busy-bg: #{colors.$color-white}CC;

  --form-input-bg-focus: #{colors.$color-white};
  --form-checkbutton-bg: #{colors.$color-white};
  --form-checkbutton-bg-focus: #{colors.$color-blue};
  --form-checkbutton-color-focus: #{colors.$color-white};

  --broadcast-header-gradient: linear-gradient(to right, #{colors.$color-gray-lighter} 51%, rgba(255, 255, 255, 0) 75%);
  --navigation-background: linear-gradient(to right, #{colors.$color-white} 20%, #{colors.$translucent-gray} 50%);
  --epg-teaser-background: linear-gradient(90deg, #FFFFFF00 0%, #d4d4de 0%, #d4d4de 35%, #12121200 65%, #FFFFFF00 100%),
  linear-gradient(to top, #FFFFFF00 0%, #d4d4de 0%, #d4d4de 35%, #12121200 50%, #FFFFFF00 100%);

  --opaque-focus-overlay: #{colors.$opaque-blue};

  &.theme--dark {
    --body-color: #{colors.$color-gray-light};
    --body-color-secondary: #{colors.$color-gray-light};

    --item-bg: #{colors.$darkmode-card-bg-color};
    --item-bg-focus: #{colors.$color-blue-dark};
    --item-color-focus: #{colors.$color-white};
    --item-color-text: #{colors.$color-gray-very-light};

    --button-bg: #{colors.$color-gray-dark};
    --button-bg-secondary: #{rgba(colors.$color-gray-dark, colors.$opaque-alpha)};
    --button-bg-opaque: #{rgba(colors.$color-gray-dark, colors.$opaque-alpha)};
    --button-color: #{colors.$color-gray-very-light};
    --button-bg-focus: #{colors.$color-blue};
    --button-bg-hover: #{colors.$color-gray-dark};

    --form-input-bg: #{rgb(colors.$color-gray-dark, colors.$opaque-alpha)};
    --form-input-border: transparent;
    --form-input-color: #{colors.$color-gray-light};

    --translucent-bg: #{rgba(colors.$darkmode-card-bg-color, .69)};

    /***********************************************
    Web.Player
    ***********************************************/
    --item-bg-focus-light: #{colors.$color-blue-dark};

    // DROPDOWNS (keep the old implementation)
    --dropdown-bg: #{colors.$color-gray-dark};
    --dropdown-bg-hover: #{colors.$color-gray-darker};
    --dropdown-color: #{colors.$color-gray-light};
    --dropdown-border-bottom-color: #{colors.$color-gray-darker};

    --popup-background: #1c1c1c;
    --popup-full-background: #1c1c1c;
    --popup-backdrop: #{rgba(colors.$color-gray-darker, colors.$opaque-alpha)};

    --card-hover: #{colors.$color-gray-dark};

    --scrollbarBG: #{colors.$darkmode-btn-bg-color};
    --thumbBG: #{colors.$darkmode-dark};

    // custom elements
    --broadcast-details-gray-bg: #2a2a2a;
    --cpvr-provider-before: #{colors.$translucent-black};
    --lp-filter: blur(60px) contrast(50%) brightness(.2);
    --tabbar-scrolled-bg: #333333;
    --tabbar-scrolled-button-bg: #484848; // maybe call inverse
    --search-bg: #1c1c1c;
    --player-body-filter: brightness(0.1);

    /***********************************************
    Web.SmartTV
    ***********************************************/

    --epg-bg-focus: #{colors.$color-blue};
    --epg-bg-live: #{colors.$color-blue-dark};
    --epg-bg-stations: #333333;

    --player-ui-bg: #{colors.$color-gray-very-dark};

    --pill-bg: #{colors.$color-gray-dark};
    --pill-color: #{colors.$color-gray-lighter};

    --dialog-bg: #{colors.$color-gray-very-dark};

    --separator-color: #{colors.$color-gray};

    --state-busy-bg: #{colors.$color-black}CC;

    --form-input-bg-focus: #{colors.$color-gray-dark};
    --form-checkbutton-bg: #{colors.$color-gray-darker};
    --form-checkbutton-bg-focus: #{colors.$color-gray-dark};
    --form-checkbutton-color-focus: #{colors.$color-gray-light};

    --broadcast-header-gradient: linear-gradient(to right, #{colors.$color-gray-very-dark} 51%, rgba(255, 255, 255, 0) 75%);
    --navigation-background: linear-gradient(to right, #{colors.$color-gray-darker} 25%, #{rgba(colors.$color-gray-darker, .21)} 75%);
    --epg-teaser-background: linear-gradient(90deg, #FFFFFF00 0%, var(--app-bg) 0%, var(--app-bg) 35%, #12121200 65%, #FFFFFF00 100%) , linear-gradient(to top, #FFFFFF00 0%, #121212 0%, #121212 35%, #12121200 65%, #FFFFFF00 100%);

    --opaque-focus-overlay: #{colors.$opaque-blue-dark};
  }
}
