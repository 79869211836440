@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.full-width {
  width: 100%;
}

.mt-small {
  margin-top: sizes.$size-small;
}

.mt-tiny {
  margin-top: sizes.$size-tiny;
}

.mt-base {
  margin-top: sizes.$size-base;
}

.mt-large {
  margin-top: sizes.$size-xlarge;
}

.mb-small {
  margin-bottom: sizes.$size-small;
}

.mb-tiny {
  margin-bottom: sizes.$size-tiny;
}

.mb-base {
  margin-bottom: sizes.$size-base;
}

.hidden {
  display: none;
}

.fullsize-centered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin niceScrollbar() {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: sizes.$size-small;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: sizes.$size-small;
    border: 3px solid var(--scrollbarBG);
  }
}

.triangle-flag {
  width: sizes.$size-xlarge;
  height: sizes.$size-xlarge;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  color: colors.$color-white;
  background: linear-gradient(
      -135deg,
      colors.$opaque-blue 0%,
      colors.$opaque-blue 50%,
      transparent 50%,
      transparent 100%
  );

  // https://stackoverflow.com/a/21039128/1928146
  transform: scale(.9999);

  &--lg {
    width: 5rem;
    height: 5rem;
    padding: sizes.$size-tiny;
  }
}

.cursor-pointer {
  cursor: pointer;
}
