@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/borders';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/variables';
@use 'src/assets/scss/layout/helpers';
.player-mini.player-brandingday,
.player-large.player-brandingday {

  @include breakpoints.breakpoint(min-desktop) {
    .main-nav__logo {
      opacity: 1;
    }
  }

  .player-brandingday-wrapper {
    display: none;
  }
}

.player-large.player-brandingday:not(.player-listing-open) {

  .player-brandingday-wrapper {
    display: block;
  }
  .player-wrapper {
    z-index: variables.$z-index-player + 1;

    .player:not([data-fullscreen=true]) {
      width: 63vw;
      height: auto;
      margin-top: 7.5vw;
      left: 22.5vw;
      display: inline-block;
    }

    .player[data-fullscreen="true"] {
      video {
        height: 100vh!important;
        width: 100vw!important;
      }
    }




    .player-header {
      position: absolute;
      padding-left: sizes.$size-large;
    }

    .player-brandingday-wrapper {
      position: absolute;
      width: 100%;
      overflow: hidden;

      img {
        max-width: 108vw;
      }
    }
  }

  @include breakpoints.breakpoint(max-tablet) {
    @media (orientation: landscape) {
      .player-list {
        display: none;
      }

      .player-wrapper {
        .player:not([data-fullscreen=true]) {
          width: 65vw;
          height: calc(65vw / #{variables.$player-ratio-19_9});
          left: 50%;
          top: 50%;
          bottom: auto;
          transform: translate(-50%, -50%);
          margin: 0;
        }

        .player-brandingday-wrapper {
          img {
            max-width: 116vw;
            margin-left: -8vw;
          }
        }
      }
    }

    @media (orientation: portrait) {

      .main-nav__logo {
        top: 19vw;
      }

      .player-wrapper {
        .player:not([data-fullscreen=true]) {
          width: 100vw;
          height: calc(100vh - 16vw - #{variables.$main-tab-nav-height});
          left: 0;
          margin: 0;
          top: 16vw;
        }

        .player-brandingday-wrapper {
          img {
            max-width: 100vw;
          }
        }
      }
    }

  }
}

