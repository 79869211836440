@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'src/assets/scss/settings/transitions';
@use 'src/assets/scss/mixins/breakpoints';
@use 'src/assets/scss/layout/helpers';
@use 'src/assets/scss/layout/variables';

.popup__body {
  padding: sizes.$size-large;
}

.popup {
  &__backdrop {
    @include helpers.niceScrollbar();
    z-index: variables.$z-index-popup-detail;
    position: fixed;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: var(--popup-backdrop);

    &--full {
      z-index: variables.$z-index-popup-detail;
      width: 100%;
    }

    &--broadcast {
      padding-bottom: 30vh;
      z-index: variables.$z-index-popup-detail;
    }

    &--popup {
      z-index: variables.$z-index-popup-info;
    }
  }

  &__container {
    background: var(--popup-background);
    background-clip: padding-box !important;
    border: sizes.$size-xlarge solid transparent;
    width: 100%;
    max-width: 700px;

    &--large {
      max-width: 850px;
    }

    &--full {
      background-color: var(--popup-full-background);
      border: 0;
      min-height: 100vh;
      max-width: 100vw;
    }

    @include breakpoints.breakpoint(only-mobile) {
      border-width: 0;
    }
  }

  &__body {
    padding: sizes.$size-large;

    &--full {
      padding: sizes.$size-large sizes.$size-large 40vh (sizes.$size-base * 7);
      position: relative;
    }

    @include breakpoints.breakpoint(max-tablet) {
      padding: sizes.$size-base sizes.$size-small 30vh sizes.$size-small;
    }

    &__scrollable {
      @include helpers.niceScrollbar();
      overflow: auto;
      max-height: 100vh;
    }
  }

  &__buttons {
    display: flex;
    gap: sizes.$size-small;
    align-items: center;
    position: fixed;
    top: sizes.$size-medium;
    right: sizes.$size-large;
    z-index: variables.$z-index-root;

    @include breakpoints.breakpoint(max-tablet) {
      top: sizes.$size-tiny;
      right: sizes.$size-medium;
    }
  }
}

.popup_container--modal--no--padding .mat-mdc-dialog-container {
  padding: 0 !important;
}

.mat-mdc-dialog-surface {
  padding: sizes.$size-large;
}

.cdk-global-scrollblock {
  // hides angular material dialog blocking scrollbar in fullscreen
  overflow-y: hidden !important;
}

.mat-mdc-dialog-surface {
  &.mdc-dialog__surface {
    background: var(--popup-background);
  }
}
