@use "../../../assets/scss/vars/colors";
@use "../../../assets/scss/vars/config";
@use "../../../assets/scss/vars/sizes";
@use "../../../assets/scss/vars/borders";
@use "../../../assets/scss/mixins/elements";
@use "../../../assets/scss/theme/vars";

.dropdown {
  position: relative;
  display: block;

  &-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__arrow-open,
    &__arrow-close {
      float: right;
    }

    &__arrow-close {
      display: none;

      .show & {
        display: block;
      }
    }

    &__arrow-open {
      .show & {
        display: none;
      }
    }
  }

  &-menu {
    @include elements.niceScrollbar();
    display: none;
    border-radius: sizes.$size-base;
    overflow: auto;
    box-shadow: borders.$shadow-small;

    &.show {
      z-index: config.$z-index-root;
      display: flex;
      flex-direction: column;
      width: fit-content;
      max-height: 50vh;
    }
  }

  &-item {
    display: block;
    width: 100%;
    background: var(--dropdown-bg);
    padding: sizes.$size-small sizes.$size-base;
    border: 1px solid transparent;
    text-align: left;
    cursor: pointer;
    border-bottom: solid 1px var(--dropdown-border-bottom-color);
    white-space: nowrap;
    color: var(--dropdown-color);
    margin: 0;


    &:hover,
    &:focus {
      background: var(--dropdown-bg-hover);
      outline: none;
    }
  }
}

.dropdown-item {
  &.active {
    background-color: colors.$color-blue!important;
    color: colors.$color-white!important;
  }
}
