@use 'node_modules/@teleboy/web.ui/assets/scss/vars/sizes';
@use 'node_modules/@teleboy/web.ui/assets/scss/vars/colors';

/*@TODO MERGE AND CLEANUP*/
$main-navi-width-left: 6rem;
$main-tab-nav-height: 5.35rem;
$tabbar-height: calc(#{sizes.$size-base} * 6);

// PLAYER
$player-list-width: 30rem;
$player-width-mini: 25vw;
$player-wrapper-height-tiny: 6rem;
$player-wrapper-height-mini: 4rem;
$view-container-space-left: sizes.$size-large;
$player-epg-height: 9.18rem;
$player-space-between-list-player: sizes.$size-base;
$player-info-height: 4rem;
$player-ratio-19_9: 1.777777;

// Z-Indexes
$z-index-root: 1;
$z-index-search: 100;
$z-index-popup-landingpage: 200;
$z-index-popup-detail: 300;
$z-index-player: 400;
$z-index-navi: 500;
$z-index-popup-info: 600;

// EPG
$epg-lane-height: 80px;
$epg-lane-height-mobile: 50px;
$epg-station-limit: 10;
$minute-pixels: 10px;
$minute-pixels-mobile: 4px;
$slider-height: calc(#{sizes.$size-base} * 5);
$timeline-height: 50px;

// THEME
$theme-color: #d2d5de;
$theme-color-darkmode: colors.$darkmode-dark;
