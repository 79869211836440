@use "sizes";
@use "colors";

// RADIUS
$border-radius-rounded: 999rem;
$border-radius: sizes.$size-tiny;
$border-radius-tiny: 3px;
$border-radius-small: 6px;
$border-radius-medium: 10px;
$border-radius-large: 24px;
$border-radius-huge: 40px;
$border-radius-circle: 50%;

// SHADOWS
$shadow: 0px 0px sizes.$size-base colors.$transparent-black;
$shadow-small: 0px 0px sizes.$size-tiny colors.$transparent-black;
$shadow-medium: 0 0 sizes.$size-small colors.$transparent-black;
$shadow-large: 0 0 sizes.$size-medium colors.$transparent-black;
